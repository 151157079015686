import React, { useEffect, useState } from 'react';
import { Grid, Layout as AntdLayout } from 'antd';
import type { RefineLayoutLayoutProps } from '@pankod/refine-ui-types';
import { Header as DefaultHeader } from './header';
import { Sidebar } from './sidebar';
import { ExchangePointsProvider } from '../../contexts/exchange-points-context';
import { useResize } from '../../hooks/useResize';
import fb from '../../../packages/fb/fb';
import { useLocation } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import CheckSession from '../../../app/CheckSession';
import { Banner } from '../ui/banner/Banner';

export const Layout: React.FC<
  RefineLayoutLayoutProps & {
    noPadding?: boolean;
  }
> = ({ children, Header, Sider, Footer, OffLayoutArea, noPadding }) => {
  const SiderToRender = () => {
    return <Sidebar />;
  };

  const HeaderToRender = Header ?? DefaultHeader;

  const { t } = useTranslation(['shared']);
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;
  const { isScreen1300 } = useResize();
  const [isPortrait, setIsPortrait] = useState(false);
  const { pathname } = useLocation();

  const checkOrientation = () => {
    const isPortraitOrientation =
      window.screen.orientation.type === 'portrait-primary' ||
      window.screen.orientation.type === 'portrait-secondary';
    if (
      pathname.includes('/create-company') ||
      pathname.includes('/login-error') ||
      pathname.includes('/create-company-error') ||
      pathname.includes('/login') ||
      localStorage.getItem('creatingCompany') ||
      pathname === '/'
    ) {
      return;
    }
    setIsPortrait(isPortraitOrientation);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [pathname]);

  return (
    <CheckSession>
      {isPortrait && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: '9999',
            background: '#fff',
            overflow: 'hidden',
          }}
        >
          <p style={{ textAlign: 'center' }}>
            {t('shared:ошибки.Пожалуйста, измените ориентацию на альбомную')}
          </p>
        </div>
      )}
      <ExchangePointsProvider>
        <AntdLayout
          style={{
            minHeight: '100vh',
            background: '#f5f5f5',
            position: 'relative',
          }}
        >
          <>{SiderToRender()}</>
          <AntdLayout style={{ background: '#FCFCFD' }}>
            <HeaderToRender />
            <AntdLayout.Content>
              <div
                style={{
                  minHeight: 360,
                  height: '100%',
                  padding: noPadding
                    ? 0
                    : isSmall
                    ? isScreen1300
                      ? 32
                      : 16
                    : 12,
                  paddingBottom: noPadding ? 0 : 32, //0
                  marginLeft: isScreen1300 ? '0px' : '80px',
                }}
                onClick={() => {
                  !isScreen1300 && fb.emit('closeSideBar', true);
                }}
                onTouchStart={() => {
                  !isScreen1300 && fb.emit('closeSideBar', true);
                }}
              >
                {children}
              </div>
              {OffLayoutArea && <OffLayoutArea />}
            </AntdLayout.Content>
            {Footer && <Footer />}
            {/*<Banner/>*/}
          </AntdLayout>
        </AntdLayout>
      </ExchangePointsProvider>
    </CheckSession>
  );
};
