import { Button } from '@pankod/refine-antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextWrapper } from './TextExpandableBlock.styles';

interface IProps {
  text: string;
  rows?: number;
}
const TextExpandableBlock = ({ rows = 3, text }: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedText, setTruncatedText] = useState(text);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = textRef.current;
    element?.style.setProperty('display', 'flex');
    if (element) {
      const lineHeight = parseInt(
        getComputedStyle(element).lineHeight || '0',
        10
      );
      const maxHeight = lineHeight * rows; // Максимум rows строк
      if (element.scrollHeight > maxHeight) {
        setIsTruncated(true);
        truncateTextToFitButton(text, element, lineHeight);
      } else {
        setTruncatedText(text);
      }

      element?.style.setProperty('display', 'inline');
    }
  }, [text]);

  const truncateTextToFitButton = (
    text: string,
    element: HTMLElement,
    lineHeight: number
  ) => {
    // Обрезаем текст, чтобы кнопка поместилась в третьей строке
    let truncated = text;
    while (element.scrollHeight > lineHeight * rows && truncated.length > 0) {
      truncated = truncated.slice(0, -30); // Уменьшаем длину текста
      element.innerHTML = truncated + '...';
    }
    setTruncatedText(truncated + '...');
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <TextWrapper ref={textRef} $rows={rows} $isExpanded={isExpanded}>
        {isExpanded ? text : truncatedText}
      </TextWrapper>
      {isTruncated && (
        <Button type={'link'} onClick={toggleExpand}>
          {isExpanded ? t('shared:тексты.Свернуть') : t('shared:тексты.Ещё')}
        </Button>
      )}
    </div>
  );
};

export default TextExpandableBlock;
