import { Flex } from '../../../../shared/components/styled';
import { Button, ButtonProps } from '@pankod/refine-antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { reorderArray } from '../../../../shared/helpers/reorderArray';

interface IProps {
  transitionStatuses: string[];
  callBack: (trigger: string) => void;
  dealCurrentStatus: string;
}

const ActionsBtnBlock = ({
  transitionStatuses,
  callBack,
  dealCurrentStatus,
}: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);

  const getBtnIcon = (status: string): ButtonProps['icon'] => {
    switch (status) {
      case 'completed': {
        return <CheckOutlined style={{ color: 'rgba(82, 196, 26, 1)' }} />;
      }
      case 'on_argument': {
        return (
          <ExclamationCircleOutlined
            style={{ color: 'rgba(250, 173, 20, 1)' }}
          />
        );
      }
      case 'expired':
      case 'cancelled': {
        return <CloseOutlined style={{ color: 'rgba(255, 77, 79, 1)' }} />;
      }
      case 'accepted': {
        return (
          <CheckOutlined style={{ color: 'rgba(82, 196, 26, 1)' }}/>
        );
      }
      default: {
        return undefined;
      }
    }
  };

  const onClickHandler = (trigger: string) => {
    callBack(trigger);
  };

  return (
    <Flex gap={16}>
      {reorderArray(transitionStatuses)?.map((status) => {
        return (
          <Button
            onClick={() => onClickHandler(status)}
            key={status}
            icon={getBtnIcon(status)}
            type={'default'}
          >
            {status === 'on_argument' && <span>{t('Открыть спор')}</span>}
            {status === 'completed' && <span>{t('Получил наличные')}</span>}
            {status === 'expired' || status === 'cancelled' ? (
              <span>
                {t(
                  `${
                    dealCurrentStatus === 'pending'
                      ? t('Отклонить')
                      : t('shared:кнопки.Отменить')
                  }`
                )}
              </span>
            ) : null}
            {status === 'accepted' && <span>{t('shared:кнопки.Принять')}</span>}
          </Button>
        );
      })}
    </Flex>
  );
};

export default ActionsBtnBlock;
