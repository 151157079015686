import styled from 'styled-components';

export const EmptyOffersStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
  width: 100%;
  gap: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 16px;
  svg {
    height: 70px;
    width: 55px;
    color: rgba(0, 0, 0, 0.25);
  }
`;
export const CancelButtonInDropdownStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: rgba(255, 77, 79, 1);
  &svg {
    color: rgba(255, 77, 79, 1);
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;
export const AcceptButtonInDropdownStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #1677ff;
  &svg {
    color: #1677ff;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const ButtonInDropdownStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.88);
  &svg {
    color: rgba(0, 0, 0, 0.88);
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const CenteredStatusCaseStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
